<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent
          class="py-8"
          title="In-Process Koenen Test Standardization"
          size="large"
        />

        <p class="font-bold">
          The
          <router-link class="link" to="/ip-koenen-test-2"
            >Koenen Test</router-link
          >
          is an in-process test (as well as a UN Test) that is used to determine
          if a substance has explosive properties. It is also used to determine
          key parameters for use in venting calculations using the Integrated
          Violence Model or similar software. Standardized procedures for the
          Verification of Standardization (VoS) or Round Robin testing are
          below. Results of testing to date are also presented with statistical
          analysis.
        </p>

        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              VERIFICATION OF STANDARDIZATION
            </h2>

            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              Testing can be completed by any lab to verify standardization
              efforts (procedures, calibration, material, etc.). The steps below
              further detail completion of the verification or Round Robin
              testing.
            </p>

            <ol class="p-light list-decimal pl-10 mt-4">
              <li>Download and implement the above detailed procedures,</li>
              <li>
                Obtain the tert-butyl peroxybenzoate (Luperox-P or equivalent),
              </li>
              <li>
                Complete testing according to the detailed instructions in the
                respective standard Round Robin/VoS procedure (link at right
                under 'Standardized Procedures', if not shown, request access
                <router-link
                  style="color:#ef9a3d;"
                  to="/standardization-and-verification/round-robin-participation"
                  >here</router-link
                >), and
              </li>
              <li>
                Share your results with the ET Users Group community. You can
                <router-link class="link" to="/contact-us"
                  >contact us here</router-link
                >.
              </li>
            </ol>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Sample Details
            </h2>

            <p class="p-light mb-4">
              The sample used for testing is tert-butyl peroxybenzoate (CAS
              Number 614-45-9, Luperox-P); it is a liquid organic peroxide. Each
              participating laboratory should have access to this in their own
              country and should be able to obtain it from a local supplier. If
              that is not the case please
              <router-link class="link" to="/contact-us">Contact Us</router-link
              >. An image of a recommended supplier is below (also given in the
              procedure).
            </p>

            <a
              :href="
                require('../../assets/tert-butyl-peroxybenzoate-details-sigma-1024x420-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/tert-butyl-peroxybenzoate-details-sigma-1024x420-min.png"
              />
            </a>

            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ET Users Group Results (To Date)
            </h2>
            <p class="p-light">
              Six laboratories have participated in the Koenen Round Robin with
              a range of values for the limiting diameter from 2 to 5 mm.
              <img
                class="m-auto"
                src="@/assets/KoenenRRResults.png"
                width="250"
              />
            </p>
            <p
              class="p-light mt-6"
              v-if="$store.getters['user/userClasses']['role-koenen-test']"
            >
              Draft results are documented in the summary presentation (to be
              updated soon)
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG%20Koenen%20Draft%20Summary%20of%20Results.pdf?alt=media&token=3f31a5a9-bff4-4a07-9c96-f84b758793d0"
                >here</a
              >.
            </p>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <div
                v-if="$store.getters['user/userClasses']['role-koenen-test']"
              >
                <h2
                  class="mb-6"
                  style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
                >
                  STANDARDIZED PROCEDURES
                </h2>
                <div class="mb-6 p-light">
                  <ol class="pl-10 list-decimal">
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/8c-ProcedureKoenenTestRev0.pdf?alt=media&token=6501d563-9afa-4095-be7f-a53662984cd1"
                        target="_blank"
                        rel="noreferrer noopener"
                        >Koenen Test Procedure</a
                      >
                      (General)
                    </li>
                    <li>
                      <a
                        class="link"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/ETUG-205%20Rev%201%20Round%20Robin%20Koenen.pdf?alt=media&token=35d4b9d6-f2c8-42bb-9b44-2d52c90ed210"
                        target="_blank"
                        rel="noopener noreferrer"
                        >ETUG-205 Rev 1 Round Robin Koenen</a
                      >
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Support Documents
            </h2>
            <p class="p-light mb-4">
              The Round Robin procedure includes calibration of the heating of
              the tubes using polydimethylsiloxane otherwise known as silicone
              oil with a viscosity of 100 cSt. The specifics of the silicone oil
              to be used are given in the procedure given above. The
              polydimethylsiloxane was recently updated in the 7th edition of
              the UN Manual as the previous fluid (dibutyl phthalate) has
              adverse health effects; it is a suspected terotagen and is banned
              for use in cosmetics and toys in many places in the world.
            </p>
            <p class="p-light">
              The 7th Edition of the UN Manual calls out a heat capacity for the
              silicon oil. Located
              <a
                class="link"
                href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/SMS-1100-0399-R1.pdf?alt=media&token=c20b89f6-f4fd-4220-9206-31b81b9e4aa8"
                >here</a
              >
              is a measurement estimate of the heat capacity of the calibration
              fluid by two methods, one of which using a Differential Scanning
              Calorimeter (DSC).
            </p>
            <h2
              class="my-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Video of No Explosion (5mm Orifice)
            </h2>
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/464332824?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo464332824"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <h2
              class="my-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Video of No Explosion (3mm Orifice)
            </h2>
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/464325696?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo464325696"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
            <p class="p-light text-center">
              Courtesy of Sandia National Laboratories
            </p>
          </div>
          <div class="w-full">
            <h2
              class="mb-6 mt-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              Feedback
            </h2>
            <p class="p-light">
              If you have any feedback on the information found here or have
              completed testing please share your feedback and/or results with
              us
              <router-link class="link" to="/contact-us"
                >(Contact Us)</router-link
              >.
            </p>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-12 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
